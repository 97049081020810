import { useState } from 'react'
import { Button, Tooltip, useToaster } from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { FilesIcon } from '@enterprise-ui/icons'
import { CopyToClipboard } from 'react-copy-to-clipboard'

const CopyTcins = ({ selectedTcins }) => {
  const toaster = useToaster()
  const [copyStatus, setCopyStatus] = useState(false)

  const handleCopyText = () => {
    try {
      setCopyStatus(true)
      setTimeout(() => setCopyStatus(false), 2000) // Reset status after 2 seconds
    } catch (error) {
      toaster({
        type: 'error',
        heading: 'Copied TCINs',
        message: 'Error copying text',
      })
    }
  }

  return (
    <>
      <Tooltip
        content={copyStatus ? 'Copied TCINs to clipboard!' : 'Copy TCINs'}
        location="top"
      >
        <CopyToClipboard text={selectedTcins} onCopy={handleCopyText}>
          <Button type="ghost" className="button-bg-hover-transparent">
            <EnterpriseIcon
              icon={FilesIcon}
              size="md"
              className="hc-clr-contrast-weak"
            />
          </Button>
        </CopyToClipboard>
      </Tooltip>
    </>
  )
}

export default CopyTcins
