import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Button, Tooltip } from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { CommentIcon } from '@enterprise-ui/icons'
import CommentInputModal from '../grid/Comment/CommentInputModal'
import { refreshSearchFilters } from '../../../store/userSearchProfile'

const AddComments = ({ selectedTcins }) => {
  const dispatch = useDispatch()
  const [isModalVisible, setModalVisibility] = useState(false)
  const onClose = () => {
    setModalVisibility(false)
    dispatch(refreshSearchFilters())
  }
  return (
    <>
      <Tooltip content="Add comments" location="top">
        <Button
          type="ghost"
          className="button-bg-hover-transparent"
          onClick={() => setModalVisibility(true)}
          aria-label="add batch tcin comment button"
        >
          <EnterpriseIcon
            icon={CommentIcon}
            size="md"
            className="hc-clr-contrast-weak"
          />
        </Button>
        {isModalVisible && (
          <CommentInputModal
            visible={isModalVisible}
            onClose={() => onClose()}
            batchAdd
            tcins={selectedTcins}
          />
        )}
      </Tooltip>
    </>
  )
}

export default AddComments
