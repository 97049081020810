import { Button, Tooltip, useToaster } from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { PencilIcon } from '@enterprise-ui/icons'
import { useMutation } from '@apollo/client'
import { SET_USER_SESSION_MUTATION } from '../../../apiItemGraphql/searchActionBar'
import {
  filterWithMultipleMatches,
  filterTCINs,
} from '../../../globalUtils/searchUtills'
import { SEARCH_ACTIONS } from '../../../constants/search'
import apiConfig from '../../../globalConfig/apiConfig'
import { useFirefly } from '../../../globalUtils/useAnalytics'
import { FIREFLY_EVENTS } from '../../../constants/search'

const NavigateToMaintainScreen = ({ selectedItems }) => {
  const { handleTrackCustomEvent } = useFirefly()
  const toaster = useToaster()
  const maintainUrl = `${apiConfig.launchpad.host}${apiConfig.launchpad.page.maintain}`
  const selectedMaintainableTcins = filterTCINs(
    filterWithMultipleMatches(selectedItems, {
      isMaintainable: true,
    }),
  )

  const selectedItemsLength = selectedItems.length
  const selectedMaintainableTcinsLength = selectedMaintainableTcins.length
  const maintainActionButtonDisabled =
    selectedMaintainableTcinsLength === 0 ||
    selectedMaintainableTcinsLength > SEARCH_ACTIONS.MAX_EDITABLE
  const maintainActionButtonTooltip =
    selectedItemsLength === 0
      ? 'Edit Item Data'
      : selectedMaintainableTcinsLength < selectedItemsLength
        ? `Manage ${selectedMaintainableTcinsLength} out of ${selectedItemsLength} Items: Max ${SEARCH_ACTIONS.MAX_EDITABLE}`
        : `Manage Item Data: Max ${SEARCH_ACTIONS.MAX_EDITABLE}`

  const [setUserSession, { loading }] = useMutation(SET_USER_SESSION_MUTATION, {
    fetchPolicy: 'no-cache',
  })

  const redirectToMaintain = async () => {
    //event capture
    handleTrackCustomEvent(
      FIREFLY_EVENTS.EVENT_TYPE.GRID_ACTION,
      FIREFLY_EVENTS.CUSTOM_INTERACTION_KEYS.MAINTAIN,
      selectedMaintainableTcins?.length,
    )
    const session = {
      tcins: selectedMaintainableTcins,
    }

    try {
      const sessionData = await setUserSession({ variables: { session } })
      if (sessionData?.data?.result?.tcins) {
        // window.open(maintainUrl)
        window.location.assign(maintainUrl)
      }
    } catch (error) {
      toaster({
        type: 'error',
        heading: 'Session Error',
        message: error,
      })
    }
  }
  return (
    <>
      <Tooltip content={maintainActionButtonTooltip} location="top">
        <Button
          type="ghost"
          onClick={redirectToMaintain}
          disabled={maintainActionButtonDisabled}
          className="button-bg-hover-transparent"
          isLoading={loading}
        >
          <EnterpriseIcon
            icon={PencilIcon}
            size="md"
            className="hc-clr-contrast-weak"
          />
        </Button>
      </Tooltip>
    </>
  )
}

export default NavigateToMaintainScreen
