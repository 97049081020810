import { Button, Tooltip } from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { ChecklistIcon } from '@enterprise-ui/icons'

const UpdateTaskStatus = ({ selectedTcins }) => {
  return (
    <>
      <Tooltip content="Update Task Status" location="top">
        <Button type="ghost" className="button-bg-hover-transparent" disabled>
          <EnterpriseIcon
            icon={ChecklistIcon}
            size="md"
            className="hc-clr-contrast-weak"
          />
        </Button>
      </Tooltip>
    </>
  )
}

export default UpdateTaskStatus
