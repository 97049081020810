/* eslint-disable array-callback-return */
import axios from 'axios'
import apiConfig from '../globalConfig/apiConfig'
export const fetchCasepackRolledOutDepartments = async () => {
  try {
    const response = await axios.get(
      `${apiConfig?.casePack?.rolledOutDepartmentLists}`,
      {},
    )
    // console.log('response', response?.data)
    return response?.data
  } catch (error) {
    console.error('Error fetching product details and menus:', error)
    return {
      message: 'Something went wrong while fetching product details!',
    }
  }
}
