import { Button, Tooltip, useToaster } from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { TeacherIcon } from '@enterprise-ui/icons'
import apiConfig from '../../../globalConfig/apiConfig'
import { filteredDepartmentLists } from '../../../globalUtils/searchUtills'

const VOPActions = ({ selectedItems, departmentLists }) => {
  const toaster = useToaster()
  const isItemVOPDepartments = filteredDepartmentLists(
    departmentLists,
    selectedItems,
  )
  const selectedTcins = selectedItems?.map((item) => item.tcin, [])
  const redirectToVOPApp = async () => {
    console.log(selectedTcins)
    try {
      const itemVOPBaseUrl = `${window.location.origin}`
      const itemVOPFullUrl = `${itemVOPBaseUrl}${apiConfig.itemVop.managePath}${selectedTcins}`
      window.open(itemVOPFullUrl, '_self', 'noopener,noreferrer')
    } catch (error) {
      toaster({
        type: 'error',
        heading: 'Error Redirecting to Casepack Application',
        message:
          error.message || 'An unknown error occurred while redirecting.',
      })
    }
  }

  const isItemVopButtonDisabled =
    selectedItems?.length !== 0 &&
    isItemVOPDepartments?.length !== 0 &&
    selectedItems?.length === isItemVOPDepartments?.length

  return (
    <>
      <Tooltip
        content={
          !isItemVopButtonDisabled
            ? 'A selected Items combination of rolled-out and non-rolled-out departments'
            : 'Manage Order Points'
        }
        location="top"
        data-testid="vop-tooltip"
      >
        <Button
          type="ghost"
          onClick={redirectToVOPApp}
          className="button-bg-hover-transparent"
          disabled={!isItemVopButtonDisabled}
          data-testid="casepack-button"
        >
          <EnterpriseIcon
            icon={TeacherIcon}
            size="md"
            className="hc-clr-contrast-weak"
          />
        </Button>
      </Tooltip>
    </>
  )
}

export default VOPActions
