import { Button, Tooltip } from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { PutToHoldIcon } from '@enterprise-ui/icons'
// import apiConfig from '../../../globalConfig/apiConfig'
// import { filteredDepartmentLists } from '../../../globalUtils/searchUtills'

const FixedAssortmentAction = ({ selectedItems }) => {
  // const toaster = useToaster()
  // const isCasepackDepartments = filteredDepartmentLists(
  //   departmentLists,
  //   selectedItems,
  // )
  const selectedTcins = selectedItems?.map((item) => item.tcin, [])
  const redirectToFAApp = async () => {
    console.log(selectedTcins)
    // try {
    //   const casePackAppBaseUrl = `${window.location.origin}`
    //   const pipelineDetailsUrl = `${casePackAppBaseUrl}${apiConfig.casePack.managePath}${selectedTcins}`
    //   window.open(pipelineDetailsUrl, '_blank', 'noopener,noreferrer')
    // } catch (error) {
    //   toaster({
    //     type: 'error',
    //     heading: 'Error Redirecting to Casepack Application',
    //     message:
    //       error.message || 'An unknown error occurred while redirecting.',
    //   })
    // }
  }

  // const isCaseBackButtonDisabled =
  //   selectedItems?.length !== 0 && isCasepackDepartments.length !== 0

  return (
    <>
      <Tooltip
        content="Manage Fixed Assortments"
        location="top"
        data-testid="fa-tooltip"
      >
        <Button
          type="ghost"
          onClick={redirectToFAApp}
          className="button-bg-hover-transparent"
          // disabled={!isCaseBackButtonDisabled}
          data-testid="fa-button"
          // style={{ visibility: 'hidden' }}
        >
          <EnterpriseIcon
            icon={PutToHoldIcon}
            size="md"
            className="hc-clr-contrast-weak"
          />
        </Button>
      </Tooltip>
    </>
  )
}

export default FixedAssortmentAction
