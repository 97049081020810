import { useState, useMemo } from 'react'
import {
  Button,
  Tooltip,
  Grid,
  Modal,
  useToaster,
} from '@enterprise-ui/canvas-ui-react'
import { useMutation } from '@apollo/client'
import EnterpriseIcon, {
  TrashIcon,
  CautionFilledIcon,
} from '@enterprise-ui/icons'
import { DELETE_ITEMS } from '../../../apiItemGraphql/searchActionBar'
import {
  filterWithMultipleMatches,
  filterTCINs,
} from '../../../globalUtils/searchUtills'
import { FIREFLY_EVENTS, SEARCH_ACTIONS } from '../../../constants/search'
import messageConstants from '../../../constants/message-constants'
import { useFirefly } from '../../../globalUtils/useAnalytics'

// Utility functions
const filterTCINsByStatus = (selectedItems, status) =>
  filterTCINs(filterWithMultipleMatches(selectedItems, { isDeletable: status }))

// Custom hook for toaster messages
const useToasterMessage = () => {
  const toaster = useToaster()
  return (type, heading, message) => {
    toaster({ type, heading, message })
  }
}

const DeleteItems = ({ selectedItems }) => {
  const { handleTrackCustomEvent } = useFirefly()
  const toasterMessage = useToasterMessage()
  const [deleteItems, { loading }] = useMutation(DELETE_ITEMS)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

  const selectedItemsCount = selectedItems?.length || 0

  const deletableTcins = useMemo(
    () => filterTCINsByStatus(selectedItems, true),
    [selectedItems],
  )
  const nonDeletableTcins = useMemo(
    () => filterTCINsByStatus(selectedItems, false),
    [selectedItems],
  )

  const deletableCount = deletableTcins.length
  const nonDeletableCount = nonDeletableTcins.length

  const nonDeletableTcinsMessage = nonDeletableCount
    ? `${nonDeletableCount} of the selected items are not eligible for deletion (${nonDeletableTcins.join(', ')}).`
    : ''

  const deleteButtonDisabled =
    deletableCount === 0 || deletableCount > SEARCH_ACTIONS.MAX_DELETABLE

  const deleteButtonTooltip = useMemo(() => {
    let tooltip = `Delete Items: Max ${SEARCH_ACTIONS.MAX_DELETABLE}`
    if (deletableCount === 0) {
      tooltip += `, None of the selected items can be deleted`
    } else if (
      deletableCount < selectedItemsCount &&
      deletableCount <= SEARCH_ACTIONS.MAX_DELETABLE
    ) {
      tooltip += `, Only ${deletableCount} of ${selectedItemsCount} items are eligible for deletion`
    }
    return tooltip
  }, [deletableCount, selectedItemsCount])

  const deleteConfirmMessage = (
    <>
      <p>{`Are you sure you want to delete ${deletableCount} item(s)? This cannot be undone.`}</p>
      <p>{nonDeletableTcinsMessage}</p>
    </>
  )

  const confirmDelete = async () => {
    try {
      const result = await deleteItems({ variables: { tcins: deletableTcins } })
      if (result?.data?.deleteItems?.deleteResponses) {
        if (result?.data?.deleteItems?.status !== 'SUCCESS') {
          //event capture
          handleTrackCustomEvent(
            FIREFLY_EVENTS.EVENT_TYPE.GRID_ACTION,
            FIREFLY_EVENTS.CUSTOM_INTERACTION_KEYS.DELETE,
            deletableTcins?.length,
          )
          result?.data?.deleteItems?.deleteResponses.forEach((item) => {
            if (item.status === 'FAILURE') {
              toasterMessage(
                'error',
                `TCIN: ${item.tcin} | Status: ${item.status}`,
                item.errorMessage,
              )
            }
          })
          if (result?.data?.deleteItems?.status === 'PARTIAL') {
            toasterMessage(
              'success',
              'Delete Items',
              messageConstants.SEARCH_ACTION_DELETE_SUCCESS,
            )
            setIsDeleteModalOpen(false)
          } else if (result?.data?.deleteItems?.status === 'AUTH_ERROR') {
            toasterMessage(
              'error',
              'Delete Error!',
              messageConstants.NETWORK_ERROR_UNAUTHORIZED,
            )
          } else {
            toasterMessage(
              'error',
              'Delete Error!',
              messageConstants.SEARCH_ACTION_DEFAULT_ERROR,
            )
          }
        } else {
          toasterMessage(
            'success',
            'Delete Items',
            messageConstants.SEARCH_ACTION_DELETE_SUCCESS,
          )
          setIsDeleteModalOpen(false)
        }
      }
    } catch (err) {
      toasterMessage(
        'error',
        'Delete Error!',
        messageConstants.SEARCH_ACTION_DEFAULT_ERROR,
      )
    }
  }

  return (
    <>
      <Tooltip content={deleteButtonTooltip} location="top">
        <Button
          type="ghost"
          className="button-bg-hover-transparent"
          disabled={deleteButtonDisabled}
          onClick={() => setIsDeleteModalOpen(true)}
        >
          <EnterpriseIcon
            icon={TrashIcon}
            size="md"
            className="hc-clr-contrast-weak"
          />
        </Button>
      </Tooltip>
      <Modal
        isVisible={isDeleteModalOpen}
        headingText={
          <p>
            <EnterpriseIcon icon={CautionFilledIcon} /> Delete Item
          </p>
        }
        onRefuse={() => setIsDeleteModalOpen(false)}
        className="delete-modal"
      >
        <div className="hc-pa-normal">
          <Grid.Container>
            <Grid.Item xs>{deleteConfirmMessage}</Grid.Item>
          </Grid.Container>
          <Grid.Container
            direction="row-reverse"
            spacing="dense"
            className="hc-mt-normal hc-mb-dense"
          >
            <Grid.Item>
              <Button
                onClick={confirmDelete}
                type="destructive"
                disabled={loading}
                loading={loading}
              >
                Yes, Delete
              </Button>
            </Grid.Item>
            <Grid.Item>
              <Button
                onClick={() => setIsDeleteModalOpen(false)}
                type="secondary"
                disabled={loading}
                loading={loading}
              >
                No, Do Not Delete
              </Button>
            </Grid.Item>
          </Grid.Container>
        </div>
      </Modal>
    </>
  )
}

export default DeleteItems
