import { useState, useEffect } from 'react'
// import { useAuth } from '@praxis/component-auth'
import { Card } from '@enterprise-ui/canvas-ui-react'
import { useSelector } from 'react-redux'
import {
  getUserInfo,
  userIsInSomeRole,
} from '../../../globalUtils/commonUtills'
import NavigateToMaintainScreen from './NavigateToMaintain'
import RedirectToPipeline from './RedirectToPipeline'
import UpdateTaskStatus from './UpdateTaskStatus'
import AddComments from './AddComments'
import DownloadDQIssues from './DownloadDQIssues'
import CopyTcins from './CopyTcins'
import ValidateItemData from './ValidateItemData'
import DeleteItems from './DeleteItems'
import MMBActions from './MMBAction'
import CasepackAction from './CasepackAction'
import PresentationAttributes from './PresentationAttributes'
import VOPActions from './VOPAction'
import FixedAssortmentAction from './FixedAssortment.js'
// import CrossOver from './CrossOver'
// import Unpublished from './Unpublished'
// import ClearItems from './ClearItems'
import constants from '../../../constants/constants'
import {
  filterTCINs,
  filterWithMultipleMatches,
} from '../../../globalUtils/searchUtills'
import { fetchCasepackRolledOutDepartments } from '../../../globalServices/searchServices'

export const Actionbar = (props) => {
  // const auth = useAuth()
  const [departmentLists, setDepartmentLists] = useState([])
  const { selectedItems } = props
  const selectedTcins = selectedItems?.map((item) => item.tcin, [])
  const { searchActionButtons } = props?.actionControls

  useEffect(() => {
    getDepartmentLists()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getDepartmentLists = async () => {
    try {
      const departments = await fetchCasepackRolledOutDepartments()
      if (departments?.length) {
        setDepartmentLists(departments)
        // console.log(departmentLists)
      } else {
        setDepartmentLists([])
      }
    } catch (error) {
      console.error('Error fetching casepack department lists:', error)
      setDepartmentLists([])
    }
  }

  const getUserProfile = useSelector((state) => {
    return getUserInfo(state)
  })
  const canUserDelete = userIsInSomeRole(getUserProfile?.roles, [
    constants.USER_ROLES.ADMIN,
    constants.USER_ROLES.SUPPORT,
    constants.USER_ROLES.MAINTAINER,
  ])

  // Is Item Validator Role
  const isItemValidator = userIsInSomeRole(getUserProfile?.roles, [
    constants.USER_ROLES.VALIDATOR,
  ])

  // Is Vendor Role
  // const isVendor = userIsInSomeRole(getUserProfile?.roles, [
  //   constants.USER_ROLES.VENDOR,
  // ])

  // IsMMBItems
  const isMMB = filterTCINs(
    filterWithMultipleMatches(selectedItems, { isMMBItem: true }),
  )
  return (
    <>
      {selectedItems?.length > 0 && (
        <Card className="bg-default-interactive hc-mt-md footer-actions-section">
          <div className="action-buttons hc-pa-normal action-buttons-left-section">
            {/* <span className="hc-clr-contrast-weak">
              {selectedTcins?.length} Items Selected
            </span> */}
            {!isItemValidator && (
              <NavigateToMaintainScreen selectedItems={selectedItems} />
            )}
            {!isItemValidator && (
              <RedirectToPipeline selectedTcins={selectedTcins} />
            )}
            {!isItemValidator && (
              <UpdateTaskStatus selectedTcins={selectedTcins} />
            )}
            {!isItemValidator && <AddComments selectedTcins={selectedTcins} />}
            {!isItemValidator && (
              <DownloadDQIssues selectedTcins={selectedTcins} />
            )}
            <CopyTcins selectedTcins={selectedTcins} />
            {isItemValidator && (
              <ValidateItemData selectedItems={selectedItems} />
            )}
            {canUserDelete && <DeleteItems selectedItems={selectedItems} />}
            {isMMB.length !== 0 && (
              <>
                <span className="hc-clr-contrast-weak">|</span>
                <MMBActions
                  selectedTcins={selectedTcins}
                  selectedItems={props?.selectedItems}
                />
              </>
            )}
          </div>
          <div className="action-buttons hc-pa-normal action-buttons-middle-section">
            {/* TODO: Need to handle the Condition like Role based and Department specific controls */}
            {/* {searchActionButtons?.casepackEnable && ( */}
            <CasepackAction
              selectedItems={selectedItems}
              departmentLists={departmentLists}
            />
            {/* )} */}
            {/* {searchActionButtons?.vopEnable && ( */}
            <VOPActions
              selectedItems={selectedItems}
              departmentLists={departmentLists}
            />
            {/* )} */}
            {/* {searchActionButtons?.presentationEnable && ( */}
            <PresentationAttributes selectedItems={selectedItems} />
            {/* )} */}
            {searchActionButtons?.fixedAssortmentEnable && (
              <FixedAssortmentAction selectedItems={selectedItems} />
            )}
          </div>
        </Card>
      )}
    </>
  )
}
