import { getEnvConfig } from '@praxis/component-runtime-env'

const getTapConfig = async () => {
  const isLocalhost = window.location.hostname === 'localhost'
  return await getEnvConfig(
    isLocalhost ? '/tapConfigLocal.json' : '/app-environment',
  )
}

export default getTapConfig
