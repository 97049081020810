import { useState, useEffect } from 'react'
import {
  Button,
  Heading,
  Grid,
  Placeholder,
  Tooltip,
} from '@enterprise-ui/canvas-ui-react'
import { useSelector } from 'react-redux'

import EnterpriseIcon, {
  FilterIcon,
  MinimizeIcon,
  MaximizeIcon,
  DownloadIcon,
  LinkIcon,
} from '@enterprise-ui/icons'
import { useGridHeader } from './useGridHeader'
import { useGridDownload } from './useGridDownload'
import ClearItems from '../actionbar/ClearItems'
import {
  getUserInfo,
  userIsInSomeRole,
} from '../../../globalUtils/commonUtills'
import constants from '../../../constants/constants'

export const GridHeader = (props) => {
  const { openCustomiseColumns, setOpenCustomiseColumns, handleCopyText } =
    useGridHeader()
  const {
    handleDownload,
    handleMTADownload,
    downloadInProgress,
    downloadMtaInProgress,
  } = useGridDownload()
  const {
    openCloseToolPanel,
    getTotalResult,
    loading,
    setMaximize,
    maximize,
    gridApi,
  } = props

  const isHeaderChecked = useSelector(
    (state) => state.searchColumns.headerCheckbox,
  )
  const selectedGridItems = useSelector(
    (state) => state.searchColumns.selectedGridItems,
  )

  let selectedTcins = []
  const [disableDownloadButton, setDisableDownloadButton] = useState(true)
  if (gridApi.current?.api) {
    selectedTcins = selectedGridItems.map((filter) => filter.tcin).toString()
  }
  useEffect(() => {
    if (
      isHeaderChecked ||
      (selectedGridItems.length > 0 && selectedGridItems.length <= 10000) ||
      (!loading && getTotalResult > 0 && getTotalResult <= 10000) // Download MTA button should be enabled if the total result is less than 10K
    ) {
      setDisableDownloadButton(false)
    } else {
      setDisableDownloadButton(true)
    }
  }, [selectedGridItems, isHeaderChecked, loading, getTotalResult])

  const getUserProfile = useSelector((state) => {
    return getUserInfo(state)
  })

  const isUserAllowedForMTADownload = userIsInSomeRole(getUserProfile?.roles, [
    constants.USER_ROLES.ADMIN,
    constants.USER_ROLES.SUPPORT,
    constants.USER_ROLES.MAINTAINER,
    constants.USER_ROLES.VENDOR,
  ])

  return (
    <Grid.Container
      justify="space-between"
      className="grid-header hc-mt-none grid-header-info-container"
    >
      <Grid.Item xs={4} xl={8}>
        <Heading
          heading={6}
          className="hc-fs-md hc-lh-expanded font-color-default font-weight-700 grid-header-info-items"
        >
          {loading ? (
            <Placeholder.Text
              style={{ width: 120 }}
              className="hc-ml-dense hc-mr-dense"
            />
          ) : getTotalResult > 0 ? (
            <span>Result: ({getTotalResult.toLocaleString()})</span>
          ) : (
            <span>Results: No Items</span>
          )}
          {'  '}
          {selectedGridItems?.length !== 0 && (
            <span className="hc-fs-sm font-weight-100">
              <small className="divider-line-margin">|</small>{' '}
              {selectedGridItems.length} out of {getTotalResult} selected
            </span>
          )}
          {'  '}
          {selectedGridItems?.length !== 0 && <ClearItems gridApi={gridApi} />}
        </Heading>
      </Grid.Item>
      <Grid.Item className="hc-mr-normal">
        {loading ? (
          <div style={{ display: 'flex' }}>
            <Placeholder.Text
              emphasized
              style={{ width: 20 }}
              className="hc-ml-dense hc-mr-normal"
            />
            <Placeholder.Text
              style={{ width: 20 }}
              className="hc-ml-dense hc-mr-normal"
            />
            <Placeholder.Text
              style={{ width: 20 }}
              className="hc-ml-dense hc-mr-normal"
            />
            <Placeholder.Text
              style={{ width: 20 }}
              className="hc-ml-dense hc-mr-dense"
            />
            <Placeholder.Text
              style={{ width: 150 }}
              className="hc-ml-dense hc-mr-dense"
            />
          </div>
        ) : getTotalResult > 0 ? (
          <div className="hc-mr-dense button-container">
            <Tooltip content="Customize table columns" location="top">
              <Button
                type="ghost"
                iconOnly
                className="bg-default-contrast-weak hc-mr-dense"
                onClick={() => {
                  setOpenCustomiseColumns((p) => !p)
                  openCloseToolPanel('columns', openCustomiseColumns)
                }}
                aria-label="View colunm filters"
              >
                <EnterpriseIcon icon={FilterIcon} />
              </Button>
            </Tooltip>
            <Tooltip
              content="Get links to this page (includes current filters)"
              location="top"
            >
              <Button
                type="ghost"
                iconOnly
                className="bg-default-contrast-weak hc-mr-dense"
                onClick={handleCopyText}
                aria-label="get link"
              >
                <EnterpriseIcon icon={LinkIcon} />
              </Button>
            </Tooltip>
            <Tooltip
              content={maximize ? 'Exit Fullscreen' : 'Fullscreen'}
              location="top"
            >
              <Button
                type="ghost"
                iconOnly
                className="bg-default-contrast-weak hc-mr-dense"
                onClick={() => {
                  setMaximize(!maximize)
                }}
                aria-label="Minimize the table view"
              >
                {maximize ? (
                  <EnterpriseIcon icon={MinimizeIcon} />
                ) : (
                  <EnterpriseIcon icon={MaximizeIcon} />
                )}
              </Button>
            </Tooltip>

            <Tooltip
              content="Download Visible Columns | Max 10,000"
              location="top"
            >
              <Button
                type="ghost"
                iconOnly
                className="bg-default-contrast-weak hc-mr-dense"
                onClick={(e) => {
                  handleDownload(e, gridApi?.current?.api)
                }}
                disabled={disableDownloadButton}
                isLoading={downloadInProgress}
                aria-label="Download Visible Columns"
              >
                <EnterpriseIcon icon={DownloadIcon} />
              </Button>
            </Tooltip>
            <Tooltip
              content={
                disableDownloadButton
                  ? 'Please select the items'
                  : 'Download results with MTA (Max 10K)'
              }
              location="top"
            >
              <Button
                type="secondary"
                onClick={(e) => {
                  handleMTADownload(e, selectedTcins)
                }}
                disabled={disableDownloadButton || !isUserAllowedForMTADownload}
                isLoading={downloadMtaInProgress}
              >
                Download with MTA
              </Button>
            </Tooltip>
          </div>
        ) : (
          <></>
        )}
      </Grid.Item>
    </Grid.Container>
  )
}
