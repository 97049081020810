import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { Button } from '@enterprise-ui/canvas-ui-react'
// import EnterpriseIcon, { CancelIcon } from '@enterprise-ui/icons'
import { setHeaderCheckboxState } from '../../../store/searchColumns'

const ClearItems = ({ gridApi }) => {
  const dispatch = useDispatch()
  const clearSelectedItems = useCallback(() => {
    dispatch(setHeaderCheckboxState(false))
    gridApi?.current?.api?.deselectAll()
  }, [dispatch, gridApi])

  return (
    // <Tooltip content="Clear Items" location="top">
    <>
      <span className="hc-fs-sm font-weight-100">
        {' '}
        {'  '}
        <small className="divider-line-margin">|</small>
      </span>
      <Button
        className="clear-all-selectiion button-bg-hover-transparent"
        type="ghost"
        onClick={clearSelectedItems}
        disabled={!gridApi}
      >
        Clear selection
      </Button>
    </>
    // </Tooltip>
  )
}

ClearItems.propTypes = {
  gridApi: PropTypes.shape({
    current: PropTypes.shape({
      api: PropTypes.shape({
        deselectAll: PropTypes.func.isRequired,
      }).isRequired,
    }).isRequired,
  }),
}

export default ClearItems
